
.heroes-container {
  display: flex;
}

.hero {
  cursor: pointer;
  flex: 0 0 100px;
  border-radius: 4px;
  margin: 20px 0 0 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  justify-content: flex-start;
}

.shelter-avatar {
  width: 80px;
}
.hero-name {
  color: green;
  text-align: left;
}
.hero-stats {
  margin-left: 8px;
}
.hero-stat {
  text-align: left;
}

.level-entrance:hover {
  box-shadow: 0px 0px 3px 3px rgba(240, 240, 240,.7)
}