.title {
  animation: fade-in 2s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.difficulty-select {
  animation: flash-in 2s linear;
}

.subtitle {
  font-size: 20px;
}

@keyframes flash-in {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}