.container {
  position: absolute;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  top: 0;
  border: 4px #eee solid;
  border-radius: 4px;
  margin: 8px;
  color: #eee;
  background: rgba(0,0,0,.8);
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.back-button {
  cursor: pointer;
  border-radius: 4px;
  margin: 12px;
  margin-top: auto;
  align-self: flex-start;
  font-size: 48px;
  padding: 20px;
  opacity: .7;
}
.back-button:hover {
  opacity: 1;
}