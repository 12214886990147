
.levels-container {
  display: flex;
}

.level-entrance {
  cursor: pointer;
  width: 100px;
  height: 100px;
  flex: 0 0 100px;
  border: 4px solid #eee;
  border-radius: 4px;
  margin: 20px 0 0 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  flex-direction: column;
  justify-content: flex-start;
}

.completed {
  background: rgba(255, 255, 255, .1);
}
.completed-tag {
  color: green;
  margin-top: auto;
}

.level-entrance:hover {
  box-shadow: 0px 0px 3px 3px rgba(240, 240, 240,.7)
}