.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  z-index: 999;
}

.dialogue-container {
  position: absolute;
  top: 60%;
  height: 25%;
  width: 100%;
}

.dialogue {
  width: 66%;
  margin: auto;
  position: relative;
  margin-bottom: 40px;
  background: rgba(9, 16, 25, .9);
  box-shadow: 0px 0px 3px 3px rgba(240, 240, 240,.7),
        1px 1px 8px 8px rgba(255,0,0,.3);
  border-radius: 4px;
  color: #eee;
  height: 100%;
  text-align:left;
  padding: 16px 20px;
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  font-family: 'Karla', sans-serif;
  z-index: 2;
}

.icon {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px; 
}

.spacebar-icon {
  height: 40px; 
}

.enter-icon {
  height: 64px;
}

.avatar {
  position: absolute;
  width: 200px;
  z-index: 1;
  top: -100px;
  left: 10%;
}

.avatar-highlight {
  position: absolute;
  top: 0;
  left: calc(10% + 100px);
  box-shadow: 0px 0px 200px 80px rgba(200, 200, 255,.6);
}

.speaker-name {
  color: yellow;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}

.prompt-input {
  outline: none;
  border: none;
  box-shadow: 0px 0px 6px 3px rgba(240, 240, 240,.2);
  border-radius: 4px;
  background: rgba(240,240,240,.2);

  font-family: karla;
  font-style: italic;
  font-size: 24px;
  color: #eee;

  width: 50%;
  margin-top: 20px;
  padding: 4px;
}